import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/Image';

import classes from './Card.scss';

function Card({ image, alt, children }) {
  return (
    <div className={classes.root}>
      <Image className={classes.image} image={image} alt={alt} />

      <div className={classes.content}>{children}</div>
    </div>
  );
}

Card.propTypes = {
  image: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  alt: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Card;
