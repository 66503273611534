import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import blogImage from 'assets/images/pages/blog/1.png';

import Title from 'blocks/Title';
import Grid from 'blocks/Grid';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Card from 'components/Card';
import Heading from 'components/Heading';
import Text from 'components/Text';
import Button from 'components/Button';

const Blog = ({ data }) => {
  const items = data.allBlog.nodes;

  return (
    <Layout>
      <Seo image={{ src: blogImage }} />

      <Title
        image={{ src: blogImage }}
        alt="Batao: altijd bezig met nieuwe dingen"
        subtitle="Wij houden jou op de hoogte"
        title="Batao: altijd bezig met nieuwe dingen"
        spacing
      >
        Wij blijven op de hoogte van alles digitaal. Doe je mee?
      </Title>

      <Section color="secondary-pastel" position="left" pattern>
        <Grid>
          {items.map((item) => (
            <Card key={item._id} image={item.image} alt={item.title}>
              <Heading color="primary-light">{item.title}</Heading>

              <Text spacing="lg">{item.description}</Text>

              <Button color="primary-light" outline to={`/blog/${item.slug}`}>
                Lees verder
              </Button>
            </Card>
          ))}
        </Grid>
      </Section>

      <ContactCta />
    </Layout>
  );
};

Blog.propTypes = {
  data: PropTypes.shape({
    allBlog: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          image: PropTypes.object, // eslint-disable-line react/forbid-prop-types
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query blog {
    allBlog(sort: { fields: _created, order: DESC }) {
      nodes {
        _id
        slug
        title
        description
        image {
          sharpImage: childImageSharp {
            fixed(width: 700, height: 450) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
          publicURL
        }
      }
    }
  }
`;

export default Blog;
